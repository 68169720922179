import Vue from 'vue';
import App from './App.vue';
import router from './router';
import PrismicVue from 'prismic-vue';
import Aos from 'aos';
import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';

import linkResolver from './prismic/link-resolver';

import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'slick-carousel/slick/slick.css';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;

Vue.use(PrismicVue, {
  endpoint: window.prismic.endpoint,
  linkResolver,
});

const head = createHead();
Vue.use(UnheadPlugin);
Vue.prototype.$unhead = head;

new Vue({
  created() {
    Aos.init({
      duration: 600,
      once: true,
      easing: 'ease-in-sine',
    });
  },
  router,
  unhead: head,
  render: (h) => h(App),
}).$mount('#app');
