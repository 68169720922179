<template>
  <footer>
    <div class="container services">
      <ul class="services__list">
        <li class="services__item services__item--bold">oferta</li>
        <li class="services__item services__item--bold">/</li>
        <li class="services__item" v-for="offer in offers" :key="offer.uid">
          <router-link :to="{ name: 'single-offer', params: { slug: offer.uid } }">
            {{ getOfferTitle(offer) }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="container lower-footer">
      <div class="row">
        <div class="col-12 col-md-3">Marta Sergiej &middot; Architekt wnętrz</div>
        <div class="col-12 col-md-3">
          <a href="https://www.facebook.com/wnetrzasergiej/" rel="noopener" target="_blank">
            Facebook
          </a>
          &middot;
          <a href="https://www.instagram.com/msergiejwnetrza/" rel="noopener" target="_blank">
            Instagram
          </a>
        </div>
        <div class="col-12 col-md-3">
          <a href="tel:+48605215977"> +48 605 215 977 </a>
          &middot;
          <a href="mailto:msergiej@interia.pl"> msergiej@interia.pl </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import { asText } from '@prismicio/helpers';

  export default {
    name: 'Footer',
    data() {
      return {
        offers: [],
      };
    },
    methods: {
      getOffers() {
        this.$prismic.client
          .query(this.$prismic.Predicates.at('document.type', 'oferta'), {
            orderings: '[my.oferta.date desc]',
          })
          .then((response) => {
            this.offers = response.results;
          });
      },
      getOfferTitle(offer) {
        return asText(offer.data.title);
      },
    },
    mounted() {
      this.getOffers();
    },
  };
</script>

<style scoped lang="scss">
  @use '../AppVariables' as *;

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $bgColor;

    font-size: 0.8em;
    line-height: 1.6em;

    div {
      opacity: 0.8;
    }

    .services {
      padding: 16px 0;
      border-bottom: 1px solid rgba($fontColor, 0.3);

      &__list {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        padding: 0;
      }

      &__item {
        padding: 0;
        opacity: 0.8;

        &--bold {
          font-weight: bold;
        }
      }
    }

    .lower-footer {
      padding: 32px 0;
    }
  }

  @media (max-width: 768px) {
    footer {
      height: auto;
      padding: 25px;

      .col-12 {
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
