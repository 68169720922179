import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home'),
    },
    {
      path: '/o-mnie',
      name: 'about',
      component: () => import('./views/About'),
    },
    {
      path: '/oferta',
      name: 'offer',
      component: () => import('./views/Offer'),
    },
    {
      path: '/oferta/:slug',
      name: 'single-offer',
      component: () => import('./views/SingleOffer.vue'),
    },
    {
      path: '/wnetrza-prywatne',
      name: 'private-interiors',
      component: () => import('./views/PrivateInteriors'),
    },
    {
      path: '/wnetrza-prywatne/:slug',
      name: 'private-interior',
      component: () => import('./views/PrivateInterior'),
    },
    {
      path: '/wnetrza-prywatne/:slug/:type',
      name: 'private-interior-gallery',
      component: () => import('./views/PrivateInteriorGallery'),
    },
    {
      path: '/wnetrza-publiczne',
      name: 'public-interiors',
      component: () => import('./views/PublicInteriors'),
    },
    {
      path: '/wnetrza-publiczne/:slug',
      name: 'public-interior',
      component: () => import('./views/PublicInterior'),
    },
    {
      path: '/wnetrza-publiczne/:slug/:type',
      name: 'public-interior-gallery',
      component: () => import('./views/PublicInteriorGallery'),
    },
    {
      path: '/publikacje-i-nagrody',
      name: 'publications',
      component: () => import('./views/Publications'),
    },
    {
      path: '/publikacje-i-nagrody/:slug',
      name: 'publication',
      component: () => import('./views/Publication'),
    },
    {
      path: '/kontakt',
      name: 'contact',
      component: () => import('./views/Contact'),
    },
  ],
});
