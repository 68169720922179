<template>
  <header>
    <router-link to="/">
      <img src="../assets/logo.svg" alt="Marta Sergiej - Architekt Wnętrz" />
    </router-link>
  </header>
</template>

<script>
  export default {
    name: 'Header',
  };
</script>

<style lang="scss">
  @use '../AppVariables' as *;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bgColor;

    @media (max-width: 768px) {
      height: 80px;

      img {
        height: 40px;
      }
    }

    @media (min-width: 768px) {
      height: 100px;

      img {
        height: 50px;
      }
    }

    @media (min-width: 1940px) {
      height: 200px;

      img {
        height: 80px;
      }
    }

    a {
      &:hover {
        opacity: 1;
      }
    }
  }
</style>
