<template>
  <div id="app">
    <Header />
    <Navigation />
    <main>
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
  import Header from './components/Header';
  import Navigation from './components/Navigation';
  import Footer from './components/Footer';

  export default {
    name: 'App',
    components: {
      Header,
      Navigation,
      Footer,
    },
  };
</script>

<style lang="scss">
  @use './AppFonts' as *;
  @use './AppVariables' as *;

  body,
  html {
    padding: 0;
    margin: 0;
    font-size: 1em;
    overflow-x: hidden;
  }

  #app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $fontColor;
  }

  main {
    /**
  * 100vh - header - navigation - footer
   */
    @media (max-width: 768px) {
      min-height: calc(100vh - 80px - 80px - 160px);
    }

    @media (min-width: 768px) {
      min-height: calc(100vh - 100px - 80px - 80px);
    }

    @media (min-width: 1940px) {
      min-height: calc(100vh - 200px - 100px - 80px);
    }
  }

  .centered-flexbox {
    display: flex;
    align-items: center;
  }

  .container-bp {
    @media (max-width: 1800px) {
      max-width: calc(900px + 0.5vw);
      margin: 0 auto;
    }
  }

  .mobile-space {
    @media (max-width: 576px) {
      margin-top: 50px;
    }
  }

  .disabledScroll {
    overflow: hidden;
  }
</style>
