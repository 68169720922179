<template>
  <nav :class="{ active: menuActive }">
    <ul v-if="menuActive">
      <li>
        <router-link to="/o-mnie"> o mnie </router-link>
      </li>
      <li class="offer">
        <router-link to="/oferta">&#9662; oferta </router-link>
        <ul class="dropdown">
          <li><router-link to="/oferta"> Oferta </router-link></li>
          <li v-for="offer in offers" :key="offer.uid">
            <router-link :to="{ name: 'single-offer', params: { slug: offer.uid } }">
              {{ getOfferTitle(offer) }}
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/wnetrza-prywatne"> wnętrza prywatne </router-link>
      </li>
      <li>
        <router-link to="/wnetrza-publiczne"> wnętrza publiczne </router-link>
      </li>
      <li>
        <router-link to="/publikacje-i-nagrody"> publikacje i nagrody </router-link>
      </li>
      <li>
        <router-link to="/kontakt"> kontakt </router-link>
      </li>
    </ul>
    <a v-on:click="toggleMenu()" v-if="!menuActive" class="btn-burger">
      <div></div>
      <div></div>
      <div></div>
    </a>
  </nav>
</template>

<script>
  import { asText } from '@prismicio/helpers';

  export default {
    name: 'Navigation',
    data() {
      return {
        menuActive: true,
        offers: [],
      };
    },
    watch: {
      $route() {
        if (document.documentElement.clientWidth < 768) {
          document.body.classList.remove('disabledScroll');
          this.menuActive = false;
        }
      },
    },
    methods: {
      toggleMenu() {
        this.menuActive = !this.menuActive;

        this.menuActive
          ? document.body.classList.add('disabledScroll')
          : document.body.classList.remove('disabledScroll');
      },
      getWindowWidth() {
        if (document.documentElement.clientWidth > 768) {
          this.menuActive = true;
        } else {
          this.menuActive = false;
        }
      },
      getOffers() {
        this.$prismic.client
          .query(this.$prismic.Predicates.at('document.type', 'oferta'), {
            orderings: '[my.oferta.date desc]',
          })
          .then((response) => {
            this.offers = response.results;
          });
      },
      getOfferTitle(offer) {
        return asText(offer.data.title);
      },
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.getWindowWidth);
        this.getWindowWidth();
        this.getOffers();
      });
    },
  };
</script>

<style scoped lang="scss">
  @use '../AppVariables' as *;
  nav {
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid $bgColor;
    font-size: 1em;
    transition-duration: $duration * 4;

    .btn-burger {
      &:hover {
        div {
          transition-duration: $duration;
          &:last-of-type {
            width: 20px;
          }
        }
      }

      div {
        width: 20px;
        height: 2px;
        margin-bottom: 4px;
        background-color: $fontColor;
        transition-duration: $duration;

        &:last-of-type {
          width: 15px;
          margin-bottom: 0;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0;
        padding: 0 15px;
        color: $fontColor;

        /**
         * Important for dropdown positioning
         */
        position: relative;

        a {
          color: inherit;
          text-decoration: none;

          &:hover,
          &.router-link-active {
            opacity: 0.5;
          }

          &.active {
            color: inherit;
          }
        }
      }
    }

    .offer {
      .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $bgColor;
        list-style: none;
        padding: 10px 0;
        min-width: 150px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: none;
        z-index: 100;

        li {
          display: block;
          padding: 5px 15px;
          white-space: nowrap;

          a {
            color: $fontColor;
            &:hover,
            &.router-link-active {
              opacity: 0.8;
            }
          }
        }
      }

      &:hover {
        .dropdown {
          display: block;
        }
      }
    }

    @media (max-width: 768px) {
      /** MOBILE */
      height: 50px;
      font-size: 1.4em;
      flex-wrap: wrap;
      flex-basis: 100%;
      overflow: auto;

      &.active {
        height: calc(100vh - 130px);
      }

      ul {
        li {
          display: block;
          padding: 5px;
          text-align: center;
        }
      }

      .offer {
        .dropdown {
          display: block;
          position: static;
          box-shadow: none;
        }
      }
    }

    @media (min-width: 768px) {
      height: 80px;
      font-size: 0.9em;
    }

    @media (min-width: 1940px) {
      height: 100px;
      font-size: 1em;
    }
  }
</style>
